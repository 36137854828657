import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const CovidSafety = (props) => {
  return (
    <Layout bodyClass='page-teams'>
      <SEO title='Covid Safety' />
      <div className='intro'>
        <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-12 col-lg-12 order-1'>
              <h1>Covid Safety</h1>
              <div className='pt-6' style={{ borderTop: '1px solid black' }}>
                <p>
                  <strong>What we do to keep our patients and staff safe:</strong>
                </p>
                <ul className='pl-2'>
                  <li>
                    <p>
                      {' '}
                      We have a newly installed building-wide air exchange unit. This unit pulls all air out of building
                      and replaces it with fresh, natural air from outdoors. Each cycle takes 30 minutes.
                    </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      We screen every patient coming into our office on the phone prior to their visit with screening
                      questions to ensure they are not potential Covid-19 carriers.
                    </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      We follow strict NYSDOH disinfection wipe-down protocols even in waiting room/business office
                      areas.
                    </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      In the procedural operatory, strict full PPE including caps, gowns, masks are worn. The doctor
                      then changes all clothing for the next patient.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CovidSafety;
